.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #0a192f;
  width: 200px;
  min-height: 100%;

  padding: 25px 10px;

  box-shadow: 0px -1px 10px #020c1b;

  position: fixed;
  left: 0;
}

.sidebar-icon {
  height: 75px;
  width: 75px;

  margin: 25px 0px;
  
  color: white;
  font-size: 50px;
  text-align: center;
}

@media only screen and (max-width: 800px) {

  .sidebar-container {
    display: none;
  }

}