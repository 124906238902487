.tournament-card-container {
  height: 100px;
  width: 100%;
  padding: 10px 25px;
  z-index: 2;
  margin-bottom: 25px;
  color: white;

  background-color: #0a192f;
  border: solid white 1px;
  border-radius: 8px;
  box-shadow: inset 0px -1px 5px #0a192f;
  box-shadow: 2px 2px 10px #0a192f;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

.tournament-card-name {
  width: 50%;
  margin-right: 5px;

  font-family: bogart-semibold;
  color: white;
  letter-spacing: 4px;
  font-weight: 500;
  font-size: 20px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 1;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

.tournament-category-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 50%;

}

.tournament-date {
  width: 66%;
  text-align: left;
  white-space: nowrap;

  font-family: bogart-semibold;
  font-size: 10px;
}

.tournament-category-icon {
  max-height: 100%;
  width: 33%;

  flex-shrink: 1;
  object-fit: contain;
}

@media (min-width: 680px) and (max-width: 1280px){
  
  .tournament-card-container {
    height: 150px;
  }

  .tournament-card-name {
    font-size: 24px;
  }

  .tournament-date {
    font-size: 20px;
  }

  .tournament-category-container {
    width: 50%;
  }

}


@media (min-width: 1280px){
  .tournament-card-name {
    font-size: 30px;
  }

  .tournament-date {
    font-size: 20px;
  }

}