.calendar-container {
  width: 100%;
  height: 100%;
  padding: 25px;

  margin-bottom: 125px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

.calendar-container-title {
  color: white;
  font-size: 24px;
  font-weight: 500;
  font-family: bogart-semibold;

  text-align: center;
  padding: 25px 10px;
  border-bottom: solid white 2px;
}

.calendar-switch-container {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  font-size: 12px;
  color: white;
  font-weight: 500;
  font-family: bogart-semibold;

}

.calendar-switch-label {
  margin: 25px;
}

.calendar-navigation-container-desktop {
display: none;
}

.calendar-navigation-container-mobile {
  position: relative;
  background-color: transparent;
}

.calendar-navigation-container-mobile:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 24px;
  size: 2px;
  position: absolute;
  top: 5px;
  right: 20px;
  color: #f39c12;
  pointer-events: none;
}

.calendar-navigations-select-option {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

select::-ms-expand {
  display: none;
}

.calendar-navigations-select-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  text-indent: 10px;
  color: #f39c12;
  font-weight: 500;
  border: thin solid #f39c12;
  border-width: thin;
  border-radius: 90px;
  width: 170px;
  height: 40px;
  background-color: transparent;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);

  display: flex;
  flex-direction: row;
  align-items: center;
}


.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  font-size: 12px;
  color: white;
  font-weight: 500;
  font-family: bogart-semibold;

  margin-bottom: 50px;
}

.checkbox-label-copy {
  margin-left: 10px;
}

.checkbox-label {
  /* display: block; */
  position: relative;
  /* margin: auto; */
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;

  margin-right: 10px;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #FFFFFF;
}


.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 2px solid #FFFFFF;
}


.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #F39C12;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
-webkit-transform: rotate(45deg) scale(1);
-ms-transform: rotate(45deg) scale(1);
transform: rotate(45deg) scale(1);
opacity:1;
left: 7px;
top: 2px;
width: 6px;
height: 12px;
border: solid #F39C12;
border-width: 0 4px 4px 0;
background-color: transparent;
border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);  
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity:0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}


input[type="checkbox"] {
  outline: none;
  border: 1px solid white;
  background-color: white;
  color: white;
  appearance: auto;
  opacity: 0;
}

input[type="checkbox"]:checked {
  outline: none;
  border: 1px solid white;
  color: #F39C12;
  appearance: none;
}

.calendar-checkbox-label {
  margin-left: 10px;
}

@media (min-width: 680px) and (max-width: 1280px){
  
  .calendar-container {
    padding: 50px;
  }

  .calendar-container-title {
    width: 100%;
    font-size: 48px;
  }

  .calendar-switch-container {
    font-size: 30px;
  }

  .calendar-navigation-container, .itf-checkbox-container {
    font-size: 30px;
  }

  .calendar-navigation-container-desktop {
    display: none;
  }
  
  .calendar-month-separator {
    margin: 0px 5px;
  }

}

@media (min-width: 1280px){
  .calendar-container {
    width: 50%;
  }

  .calendar-container-title {
    width: 100%;
    font-size: 48px;
  }

  .calendar-switch-container {
    font-size: 30px;
  }

  .calendar-navigation-container, .itf-checkbox-container {
    font-size: 30px;
  }

  .calendar-navigation-container-desktop {
    font-size: 20px;
    color: white;
    font-weight: 500;
    font-family: bogart-semibold;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-bottom: 50px;
  }
  
  .calendar-month-separator {
    margin: 0px 5px;
  }

  .calendar-navigation-container-mobile {
    display: none;
  }

}