.month-card {
  height: 100px;
  width: 100%;
  padding: 10px 25px;
  z-index: 2;
  margin-bottom: 25px;
  color: white;

  background-color: #f39c12;
  border: solid white 1px;
  border-radius: 8px;
  box-shadow: inset 0px -1px 5px #f39c12;
  box-shadow: 2px 2px 10px #0a192f;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

.month-name {
  width: 50%;
  margin-right: 5px;

  font-family: bogart-semibold;
  color: white;
  letter-spacing: 4px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 1;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;
}

@media (min-width: 680px) and (max-width: 1280px){

  .month-name {
    font-size: 24px;
  }

}

@media (min-width: 1280px){

  .month-name {
    font-size: 30px;
  }

}