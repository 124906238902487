.match-detail-container {

  width: 100%;
  height: 100%;
  padding: 25px;
  margin-bottom: 125px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.match-statistics-container {

  width: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  border: 3px solid white;
  border-radius: 8px;

}

.match-statistic-categories-container, .competitor-statistics-container {

  width: 30%;
  padding: 0px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.statistic-category {

  height: 50px;
  width: 100%;
  margin: 0px;
  padding-top: 15px;

  color: white;
  font-size: 12px;

  padding-bottom: 15px;
  border-bottom: white 1px dashed;
}

.match-statistic-categories-container {
  width: 25%;

  font-size: 16px;
  font-weight: 500;
  
}

.match-statistics-title {
  color: white;
  font-weight: 500;
  font-family: bogart-semibold;
  text-align: center;
  font-size: 24px;

  padding: 25px 10px;
  margin-top: 0px;
  margin-bottom: 50px;
  border-bottom: solid white 2px;
}

#competitor-statistics-container-title {

  background-color: white;
  height: 50px;
  padding: 7px 10px;
  margin: 0px;

  color: #0a192f;
  font-weight: 500;
  font-size: 12px;
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;

  border-bottom: white 1px solid;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
}

#placeholder-category {

  height: 50px;

  border-bottom: transparent 1px solid;

  padding: 7px 10px;
  background-color: white;
  color: #0a192f;
  margin: 0px;
  text-align: center;
  vertical-align: middle;

  flex-shrink: 1;
}

.total-points {
  border-bottom: none;
}

.back-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  color: white;
  font-size: 24px;
  font-family: bogart-semibold;

  margin-left: 2px;
  margin-bottom: 25px;

}

.back-button-copy {
  margin: 0px;
  margin-left: 10px;
}

@media (min-width: 680px) and (max-width: 1280px){
  
  .match-detail-container {
    padding: 50px;
    width: 100%;
  }

  .back-button-copy {
    font-size: 30px;
    margin-left: 20px;
  }

  #match-detail-back-button {
    font-size: 30px;
  }

  .match-statistics-title {
    font-size: 36px;
  }

  .competitor-statistics-container {
    width: 34%;
  }

  .match-statistic-categories-container {
    width: 32%;
  }

  .statistic-category {
    font-size: 20px;
  }

  #competitor-statistics-container-title, #placeholder-category {
    border-right: 1px solid white;
  }

}

@media (min-width: 1280px){
  .match-detail-container {
    padding: 50px;
    width: 50%;
  }

  .back-button-copy {
    font-size: 30px;
    margin-left: 20px;
  }

  #match-detail-back-button {
    font-size: 30px;
  }

  .match-statistics-title {
    font-size: 40px;
  }

  .competitor-statistics-container {
    width: 34%;
  }

  .match-statistic-categories-container {
    width: 32%;
  }

  .statistic-category {
    font-size: 20px;
  }

  #competitor-statistics-container-title, #placeholder-category {
    border-right: 1px solid white;
  }

}