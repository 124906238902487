.tournament-detail-container {

  width: 100%;
  height: 100%;
  padding: 25px;
  margin-bottom: 125px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.discipline-switch-container {

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.discipline-switch-container-copy {

  color: white;
  font-size: 24px;
  font-weight: 500;
  font-family: bogart-semibold;
  
}

.tournament-views-container {
  width: 100%;
  padding: 25px 10px;
  margin: 25px 0px;
  margin-bottom: 50px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: solid white 2px;
  border-left: none;
  border-right: none;
  /* box-shadow: inset 0px -1px 5px #0a192f; */

}

.tournament-views-button {

  font-family: amagro-bold;
  color: white;
  width: 33%;
  background: transparent;
  border: none;

  padding: 5px;
  flex-shrink: 1;
}

#completed-matches-button {

  width: 40%;

  border-left: solid white 1px;
  border-right: solid white 1px;
  
  flex-shrink: 0;
  flex-grow: 1;

}

.match-container-copy {

  color: white;
  font-family: helvetica;
  font-size: 24px;
  font-style: italic;
  text-align: center;
}

@media (min-width: 680px) and (max-width: 1280px){
  
  .tournament-detail-container {
    padding: 50px; 
  }

  .discipline-switch-container {
    width: 75%;
  }

  .discipline-switch-container-copy {
    font-size: 30px;
  }

  .tournament-views-button {
    font-size: 20px;
  }

  .match-container-copy {
    font-size: 30px;
  }

  .back-button-copy {
    font-size: 30px;
    margin-left: 20px;
  }

  #match-detail-back-button {
    font-size: 30px;
  }

} 

@media (min-width: 1280px){
  .tournament-detail-container {
    width: 50%;
  }

  .tournament-views-button {
    font-size: 24px;
  }

}