.rankings-container {
  width: 100%;
  height: 100%;
  padding: 25px;
  margin-bottom: 125px;

  font-family: bogart-semibold;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.rankings-container-title {
  color: white;
  font-size: 24px;
  font-weight: 500;
  font-family: bogart-semibold;

  padding: 25px 10px;
  border-bottom: solid white 2px;
}

.switch-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
  color: white;
  font-weight: 500;
  font-family: bogart-semibold;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.rankings-toggle-view-container {
  width: 100%;
  margin-bottom: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.player-ranking {
  font-family: bogart-semibold;
}

#rankings-loader {
  margin-top: 50px;
}

@media (min-width: 680px) and (max-width: 1280px){
  
  .rankings-container {
    padding: 50px;
    width: 100%;
  }

  .rankings-container-title {
    font-size: 48px;
  }

  .switch-container {
    font-size: 30px;
  }

  .rankings-toggle-view-container {
    width: 60%;
  }

}

@media (min-width: 1280px){
  .rankings-container {
    padding: 50px;
    width: 50%;
  }

  .rankings-container-title {
    font-size: 48px;
  }

  .switch-container {
    font-size: 30px;
  }

  .rankings-toggle-view-container {
    width: 100%;
  }

}