.footer-container {
  display: none;
}

.slide-in-bottom-nav {
	-webkit-animation: slide-in-bottom 0.5s ease-out both;
	        animation: slide-in-bottom 0.5s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-11-3 1:57:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom-nav {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 680px) {
  .footer-container {

    background: #0a192f;
  
    width: calc(100% - 50px);
    max-width: 100vw;
    height: 75px;
    padding: 25px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  
    position: sticky;
    bottom: 0;
    z-Index: 3;
  
    box-shadow: 0px -1px 10px #020c1b;
  
  }

  .mobile-footer-icon {
    font-size: 40px;
    width: 50px;
    color:  white;
  }
}

@media (min-width: 680px) and (max-width: 1280px){

  .footer-container {

    background: #0a192f;
  
    width: calc(100% - 100px);
    max-width: 100vw;
    height: 100px;
    padding: 25px 50px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  
    position: sticky;
    bottom: 0;
    z-Index: 3;
  
    box-shadow: 0px -1px 10px #020c1b;
  
  }

  .mobile-footer-icon {
    font-size: 60px;
    width: 50px;
    color:  white;
  }
}