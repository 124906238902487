.match-card-container {
  height: 250px;
  width: 100%;
  z-index: 2;
  margin-bottom: 50px;

  overflow: hidden;

  border: solid white 3px;
  border-radius: 8px;
  box-shadow: inset 0px -1px 5px lightgray;
  box-shadow: 2px 2px 10px #0a192f;

  font-family: helvetica;
  font-size: 12px;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.match-card-header-container {
  background-color: white;
  width: 100%;
  height: 15%;
  padding: 25px 10px;
  z-index: 0;

  font-family: amagro-bold;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #172a45;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.match-card-title {
  width: 50%;
}

.match-court {

  width: 50%;
  font-size: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: right;
}

.match-container {
  background-color: #0a192f;
  width: 100%;
  max-height: 70%;
  padding: 15px 15px;

  overflow: hidden;

  font-family: helvetica;
  font-weight: 300;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 1;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.match-info-container {
  width: 100%;
  height: 20%;
  padding: 5px 1px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.match-round, .match-status {
  width: 50%;
  margin: 0px;
  
  font-family: amagro-bold;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 4px;
}

.match-round {
  text-align: left;
}

.match-status {
  text-align: right;
}

.competitor-container, .set-labels-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 30%;
  width: 100%;
  padding: 5px 10px;
  margin-top: 1%;
  margin-bottom: 1%;

  border: 1px solid white;

  text-align: left;
  font-weight: 500;

  -webkit-box-sizing: padding-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  
}

.set-labels-container {
  height: 5%;
  margin: 0px;
  border: none;
  justify-content: flex-end;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.set-score, .service-score, .service-icon, .set-label {
  width: 10%;
  height: 25px;
  line-height: 25px;
  margin: 0;

  font-size: 12px;
  text-align: center;
  vertical-align: middle;
}

.service-score {
  background-color: #f39c12;
  color: white;
  height: 100%;
  border: 1px solid white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.set-label {
  height: 5px;
  line-height: 5px;
  font-size: 8px;
}

.competitor-name-container {
  width: 30%;
  height: 25px;
  margin: 0;

  line-height: 25px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.competitor-name {
  margin-left: 5px;
}

.match-stats-container {
  background-color: #f39c12;
  width: 100%;
  height: 15%;
  padding: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.heartbeat {
	-webkit-animation: heartbeat 10s ease-in-out infinite both;
	        animation: heartbeat 10s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-15 0:16:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.emojiFlag {
  margin-right: 2px;
}


@media (min-width: 680px) and (max-width: 1280px){

  .match-card-container {
    height: 400px;
  }

  .match-card-header-container {
    font-size: 24px;
  }

  .match-court {
    font-size: 20px;
  }

  .match-container {
    height: 65%;
  }

  .match-round, .match-status {
    font-size: 20px;
  }

  .match-info-container {
    height: 15%;
  }

  .set-labels-container {
    height: 10%;
  }

  .set-score, .service-score, .service-icon, .name-container, .competitor-name, .emojiFlag {
    font-size: 20px;
  }

  .set-label {
    font-size: 12px;
  }

  .emojiFlag {

    margin-right: 10px;
  }

  .match-card-header-container, .match-stats-container {
    height: 17.5%
  } 


  .match-stats-container {
    font-size: 24px;
  }

}

@media (min-width: 1280px){

  .match-card-container {
    height: 400px;
  }

  .match-card-header-container {
    font-size: 24px;
  }

  .match-court {
    font-size: 20px;
  }

  .match-container {
    height: 65%;
  }

  .match-round, .match-status {
    font-size: 20px;
  }

  .match-info-container {
    height: 15%;
  }

  .set-labels-container {
    height: 10%;
  }

  .set-score, .service-score, .service-icon, .name-container, .competitor-name, .emojiFlag {
    font-size: 20px;
  }

  .set-label {
    font-size: 12px;
  }

  .emojiFlag {

    margin-right: 10px;
  }

  .match-card-header-container, .match-stats-container {
    height: 17.5%
  } 


  .match-stats-container {
    font-size: 24px;
  }

}