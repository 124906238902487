.order-of-play-container {
  width: 100%;
  height: 100%;
  padding: 25px;

  margin-bottom: 125px;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.upcoming-match-container {
  width: 100%;
  padding: 25px 10px;
  border-bottom: white 1px solid;

  font-size: 12px;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.upcoming-match-time {
  width: 100%;
  padding: 10px 5px;

  color: white;
  font-size: 12px;
  font-weight: 500;
  font-family: bogart-semibold;
  text-align: center;

  background-color: #F39C12;
  border-bottom: 3px solid white;

}

.upcoming-match-competitors-container {
  display: flex;
  flex-direction: row;
  flex-direction: flex-start;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

  padding: 10px 5px;
}

.player-separator {
  margin: 0px 10px;
}

.emojiFlag {

  margin-right: 2px;

}

@media (min-width: 680px) and (max-width: 1280px){

  .upcoming-match-container {
    font-size: 24px;
  }

  .emojiFlag {
    margin-right: 10px;
  }

  .upcoming-match-time {
    font-size: 24px;
  }
}


@media (min-width: 1280px){

  .upcoming-match-container {
    font-size: 24px;
  }

  .emojiFlag {
    margin-right: 10px;
  }

  .upcoming-match-time {
    font-size: 24px;
  }

}