.home-container {
  width: 100%;
  height: 100%;
  padding: 25px;

  margin-bottom: 125px;
}

.home-copy {
  color: white;
  font-weight: 500;
  font-family: bogart-semibold;
  text-align: center;
  font-size: 24px;

  padding: 25px 10px;
  margin-bottom: 50px;
  border-bottom: solid white 2px;
}

.tournaments-container-copy {

  color: white;
  font-family: helvetica;
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
}

@media (min-width: 680px) and (max-width: 1280px){
  
  .home-container {
    padding: 50px;
    width: 100%;
  }

  .home-copy {
    font-size: 48px;
  }

}

@media (min-width: 1280px){
  .home-container {
    padding: 50px;
    width: 50%;
  }

  .home-copy {
    font-size: 48px;
  }

}