.tournaments-container {

  width: 100%;
  height: 100%;
  padding: 25px;

  margin-top: 10px;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.calendar-loader-container {
  margin-top: 100px;
}