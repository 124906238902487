.header-container{
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;
}

.desktop-nav {

  background: #0a192f;

  max-width: 100vw;
  height: 75px;
  padding: 25px 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;

  box-shadow: 0px 1px 10px #020c1b;

}

.logo {
  height: 75px;
}

.desktop-logo-container {
  width: 20%;
  flex-shrink: 1;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.desktop-nav-links-container {
  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 50%;
  flex-grow: 1;
}

.desktop-nav-link {

  font-family: bogart-semibold;
  font-size: 25px;
  color: white;
  margin: 0px 25px;

  text-decoration: none;
  text-decoration-line: none;

}

#login-link, #current-user-name {
  border: solid white 3px;
  padding: 10px;
}

.mobile-nav {
  display: none;
}

.mobile-menu-hidden {
  display: none;
}

.mobile-menu-visible {
  display: none;
}

  .slide-in-top-header {
    -webkit-animation: slide-in-top 0.5s ease-out both;
            animation: slide-in-top 0.5s ease-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2020-11-3 1:55:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top-header {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


@media only screen and (max-width: 680px) {

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    background: #0a192f;

    max-width: 100vw;
    height: 50px;
    padding: 15px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    position: sticky;
    top: 0;
    z-Index: 3;
  
    box-shadow: 0px 1px 10px #020c1b;
  }

  .mobile-logo {
    height: 50px;
  }

  .mobile-icon {
    width: 50px;
    color: white;
    font-size: 40px;
  }

  .mobile-header-placeholder {
    width: 50px;
  }

  .desktop-nav-links-container {
    display: none;
  }

  .mobile-menu-visible {
    display: block;
    position: relative;
    top: -1;
    z-index: 5;

    background-color: #020c1b;
    height: 100vh;
    overflow: hidden;
    /* opacity: 0.7; */

    padding: 10px 25px 0px 25px;
  }

  .mobile-nav-link {
    min-width: 100vw;
    display: block;

    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin: 0px;
    padding: 10px 0px 15px 0px;

    border-bottom: #020c1b 1px solid;

    text-decoration: none;
    text-decoration-line: none;
  }

}

@media (min-width: 680px) and (max-width: 1280px){

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    background: #0a192f;

    max-width: 100vw;
    height: 75px;
    padding: 25px 50px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    position: sticky;
    top: 0;
    z-Index: 3;
  
    box-shadow: 0px 1px 10px #020c1b;
  }

  .mobile-logo {
    height: 50px;
  }

  .mobile-icon {
    width: 50px;
    color: white;
    font-size: 60px;
  }

  .mobile-header-placeholder {
    width: 50px;
  }

  .desktop-nav-links-container {
    display: none;
  }

  .mobile-menu-visible {
    display: block;
    position: relative;
    top: -1;
    z-index: 5;

    background-color: #020c1b;
    height: 100vh;
    overflow: hidden;
    /* opacity: 0.7; */

    padding: 25px 50px 0px 50px;
  }

  .mobile-nav-link {
    min-width: 100vw;
    display: block;

    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    margin: 0px;
    padding: 20px 0px 30px 0px;

    border-bottom: #020c1b 1px solid;

    text-decoration: none;
    text-decoration-line: none;
  }

}

